/* global axios */
export default class SharecodeService {
  constructor() {
    this.axios = axios;
  }

  save(sharecodes) {
    return this.axios.post('service/sharecode/update', sharecodes);
  }
}
