<template>
  <div id="sharecodes">
    <header-service
      :service="service"
      currentTab="Sharecodes"
      :isSaving="!isLoading && isSaving"
      @saving="save"
      :actionScopes="['service-store-sharecodes']"
    >
    </header-service>
		<div class="row mt-5">
      <div class="col-lg-3 col-md-4">
        <menu-service :service="service"></menu-service>
      </div>
      <div class="col-lg-9 col-md-8" ref="form">
        <div class="card" v-show="!isLoading && sharecodes.length == 0">
          <div  class="card-body">
              <div class="text-center">
                Cadastre uma oferta para configurar os Sharecodes.
              </div>
          </div>
        </div>
        <!-- Start Sharecodes -->
        <div class="form-row" v-if="!isLoading && sharecodes.length"  v-for="(sharecode, index) in sharecodes" :key="index">
          <div class="col-lg-12">
            <u-portlet :title="sharecode.name">
              <div class="form-row mt-3" v-if="sharecode" v-for="(tariff, indexTariff) in sharecode.tariffs" :key="indexTariff">
                <div class="form-group col-md-1"></div>
                <div class="form-group col-md-3">
                  <label>Tarifa</label>
                  <money
                    v-model="sharecodes[index]['tariffs'][indexTariff].price"
                    v-bind="moneyOptions"
                    class="form-control tariff"
                    disabled
                  >
                  </money>
                </div>
                <div class="form-group col-md-3">
                  <label>Sharecode</label>
                  <input type="text" maxlength="6" :disabled="!$authorization.can('service-store-sharecodes') || inputDisable" v-model="sharecodes[index]['tariffs'][indexTariff].sharecode" class="form-control">
                </div>
                <div class="form-group col-md-3">
                  <label>Billing Code</label>
                  <input type="text" maxlength="6" :disabled="!$authorization.can('service-store-sharecodes') || inputDisable" v-model="sharecodes[index]['tariffs'][indexTariff].billing_code" class="form-control">
                </div>
                <div class="form-group col-md-1 mt-4 pt-3 text-center">
                  <div v-if="!indexTariff" class="custom-control custom-radio ml-4">
                    <input 
                      :disabled="inputDisable"
                      type="radio" 
                      :name="`${sharecode.name}`" 
                      :id="`${sharecode.name}-tariffs-${indexTariff}`" 
                      value="1"
                      v-model="sharecodes[index]['tariffs'][indexTariff].sharecode_master" 
                      class="custom-control-input"
                    >
                    <label class="custom-control-label" :for="`${sharecode.name}-tariffs-${indexTariff}`">Pai</label>
                  </div>
                </div>
              </div>
            </u-portlet>
          </div>    
        </div> 
        <!-- End Sharecodes -->
      </div> 
    </div>
  </div>
</template>

<script>
import {Money} from 'v-money';
import HeaderService from '../components/HeaderService';
import MenuService from '../components/MenuService';
import ServiceService from '../services/ServiceService';
import SharecodeService from '../services/SharecodeService';
import UPortlet from '@/components/UPortlet';

export default {
  name: 'sharecodes',
  components: {
    HeaderService,
    MenuService,
    Money,
    UPortlet,
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      service: {},
      sharecodes: {},
      serviceService: new ServiceService(),
      sharecodeService: new SharecodeService(),
      inputDisable: !this.$authorization.can('service-store-sharecodes'),
      moneyOptions: {
        decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false,
      },
    };
  },
  created() {
    this.getService();
  },
  watch: {
    '$store.state.service.lock': function(newValue, oldValue){
      this.lockForm(newValue);
    }
  },
  methods: {
    getService() {
      this.serviceService.getService(this.$route.params.id)
        .then((response) => {
          this.service = response.data;
          this.sharecodes = response.data.serviceSharecodes;
          this.isLoading = false;
          this.preventEditForm();
        })
    },
    save() {
      this.isSaving = true;
      this.sharecodeService.save(this.sharecodes)
        .then((response) => {
          this.isSaving = false;
          this.getService();
          swal('Sucesso!', 'Serviço atualizado com sucesso.', 'success');   
        })
        .catch((error) => {
          this.isSaving = false;
          console.log(error.response);
          if (error.response.status === 422 && error.response.data.sharecode) {
            swal( 'Ops!' ,  `Dados Inválidos. Sharecode ${error.response.data.sharecode} existe no serviço ${error.response.data.service_name}.` ,  'error');
          } else {
            swal( 'Ops!' ,  'Dados Inválidos. Verifique os dados informados e tente novamente.' ,  'error');
          } 
        })
    },
    clearMasterSharecode(sharecodeName) {
      if (this.sharecodes.length) {
        this.sharecodes.map((sharecode, key) => {
          if (sharecode.name === sharecodeName && sharecode.tariffs.length) {
            sharecode.tariffs.map((tariff) => {
              tariff.sharecode_master = '0';
            })
          }       
        });
      }
    },

    lockForm(isLocked) {
      const form = $(this.$refs.form);

      if (isLocked) {
        form.find('input, textarea, select').prop('disabled', true);
      } else {
        form.find('input, textarea, select').prop('disabled', false);
        $('.tariff').prop('disabled', true);
      }
    },

    preventEditForm() {
      setTimeout(() => {
        if(this.service.status_register === 'complete') {
          this.$store.commit('setLock', true);
          this.lockForm(true);
        }
      }, 0)
    }
  }, 
};
</script>
